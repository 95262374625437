import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';
import { PageHeader, PageIntro, PageSection, RelatedContent, ResourceLibrary, QrComponent, StaticTooltip } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `HCP & Patient Resources for KEYTRUDA® (pembrolizumab) | HCP`,
    keywords: `keytruda hcp resources, keytruda patient resources`,
    description: `Health care professionals may find downloadable guides to use and share with their patients before and during treatment with KEYTRUDA® (pembrolizumab).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/resources/hcp-and-patient-resources/","@type":"MedicalWebPage","name":"HCP & Patient Resources","description":"Below you will find downloadable guides and information to use during treatment with KEYTRUDA."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/resources/hcp-and-patient-resources/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico. "}`
    ]
}

const jobCode = jobCodes[31].jobCode;

const relatedContent = [
    {
        section: 'Safety',
        link: {
            url: '/safety/adverse-reactions/',
            label: 'Selected Adverse Reactions'
        }
    },
    {
        section: 'Biomarker Testing',
        link: {
            url: '/biomarker-testing/pd-l1/',
            label: 'PD&#8288;-&#8288;L1 Testing & Scoring'
        }
    },
    {
        section: 'Resources',
        link: {
            url: '/resources/key-you-patient-support-program/',
            label: 'Patient Support Program'
        }
    },
]

const resourceLibraryItemsForHcps = [
    {
        label: 'Biomarker Eligibility Brochure',
        url: '/static/pdf/HCP_Biomarker_Eligibility_Brochure.pdf',
        resourceBodyText: 'A guide to biomarker testing and cut points for relevant tumor types and indications.',
        image: () => (
            <StaticImage src="../../assets/resource-biomarker-eligibility-brochure.png" placeholder="TRACED_SVG" alt="Download a Guide on Biomarker Testing" />
        ),
    },
    {
        label: 'Patient Monitoring Checklist',
        url: '/static/pdf/Patient_Monitoring_Checklist.pdf',
        resourceBodyText: 'Identify and monitor some of the signs and symptoms associated with adverse reactions related to treatment with KEYTRUDA.',
        image: () => (
            <StaticImage src="../../assets/patient-monitoring-checklist.png" placeholder="TRACED_SVG" alt="Download Patient Monitoring Checklist for KEYTRUDA® (pembrolizumab)" />
        ),
    },
    {
        label: 'Signs and Symptoms of Select Immune-Mediated Adverse Reactions (IMARs) and Other Adverse Reactions',
        url: '/static/pdf/Signs_and_Symptoms_of_Select_IMARs_and_Other_ARs_Brochure.pdf',
        resourceBodyText: 'Signs and symptoms of select immune-mediated adverse reactions (IMARs) and other adverse reactions for KEYTRUDA.',
        image: () => (
            <StaticImage src="../../assets/resource-imar-and-other-ars.png" placeholder="TRACED_SVG" alt="Download Information on Signs and Symptoms of Select Immune-Mediated Adverse Reactions (IMARs) and Other Adverse Reactions for KEYTRUDA® (pembrolizumab)" />
        ),
    },
    {
        label: 'KEYTRUDA: A Treatment Guide for Urologists',
        url: '/static/pdf/US-KEY-06910.pdf',
        resourceBodyText: 'A guide to select indications, mechanism of action, dosing options and modifications, preparation/administration, and monitoring/management of adverse reactions.',
        image: () => (
            <StaticImage src="../../assets/resource-uro-treatment-guide.png" placeholder="TRACED_SVG" alt="Download a Treatment Guide for Urologists" />
        ),
        disabled: true,
    },
]

const resourceLibraryItemsForPatients = [
    {
        label: 'A Guide to Your Cancer Care Team',
        url: '/static/pdf/A_Guide_to_Your_Cancer_Care_Team.pdf',
        resourceBodyText: 'This guide helps patients understand the role of each specialist on their cancer support team and the importance of taking an active part in their own care.',
        image: () => (
            <StaticImage src="../../assets/resource-a-guide-to-your-cancer-care-team.png" placeholder="TRACED_SVG" alt="Download a Guide on the Patient Care Team for KEYTRUDA® (pembrolizumab)" />
        ),
    },
    {
        label: 'Build Your Support Community Guide',
        url: '/static/pdf/Build_Your_Support _Community_Guide.pdf',
        resourceBodyText: 'This guide helps patients build a strong support community, and includes lists for them to fill out on who can help them with their needs.',
        image: () => (
            <StaticImage src="../../assets/build-your-support-community-guide.png" placeholder="TRACED_SVG" alt="Download a Guide on How to Build Your Support Community When Receiving Treatment With KEYTRUDA® (pembrolizumab)" />
        ),
    },
    {
        label: 'Doctor Conversation Guide: Questions to Ask Before Starting Treatment',
        url: '/static/pdf/Doctor_Conversation_Tool_Guide_Questions_to_Ask_Before_Starting _Treatment.pdf',
        resourceBodyText: 'A guide for patients and caregivers with information and discussion points about starting treatment with KEYTRUDA.',
        image: () => (
            <StaticImage src="../../assets/resource-doctor-conversation-guide-questions-to-ask-before-treatment.png" placeholder="TRACED_SVG" alt="Download a Guide on Doctor Discussions That Can Be Used Before Starting a Treatment With KEYTRUDA® (pembrolizumab)" />
        ),
    },
    {
        label: 'Doctor Conversation Guide: Questions to Ask While on Treatment',
        url: '/static/pdf/Doctor_Conversation_Guide_Questions_to_Ask_While_on_Treatment.pdf',
        resourceBodyText: 'This guide for patients includes information and important questions to ask during the entire treatment journey, from just starting out to when treatment is finished.',
        image: () => (
            <StaticImage src="../../assets/resource-doctor-conversation-guide-questions-to-ask-while-on-treatment.png" placeholder="TRACED_SVG" alt="Download a Guide on Questions to Ask Your Doctor During Treatment With KEYTRUDA® (pembrolizumab)" />
        ),
    },
    {
        label: 'Food, Communication, and Confidence Guide',
        url: '/static/pdf/Food_Communication_and_Confidence_Guide.pdf',
        resourceBodyText: 'Information for patients and caregivers about managing meals during treatment, and how to deal with feelings and social interactions that revolve around food. <br/><br/><a disabled="true" href="/static/pdf/Food_Communication_and_Confidence_Guide_Version_en_espanol.pdf" target="_blank">Versión en español</a>',
        image: () => (
            <StaticImage src="../../assets/food-communication-and-confidence-guide.png" placeholder="TRACED_SVG" alt="Download a Guide on How to Manage Meals During Treatment With KEYTRUDA® (pembrolizumab)" />
        ),
    },
    {
        label: 'KEY+YOU Brochure',
        url: '/static/pdf/KEY_YOU_Brochure.pdf',
        resourceBodyText: 'This brochure provides an overview of the KEY+YOU Patient Support Program, including the educational resources offered, and how patients may contact a KEY+YOU representative. ',
        image: () => (
            <StaticImage src="../../assets/key-plus-you-brochure.png" placeholder="TRACED_SVG" alt="Download KEY+YOU Patient Support Program Brochure" />
        ),
        disabled: true,
    }
]

const Page = ({ location }) => {
    // AT/screenshots hook to disable all Tertiary Buttons and resourceBodyText links
    const [allResourcesDisabled, setAllResourcesDisabled] = useState(false);
    useEffect(() => {
        const disableHandler = (event) => {
            if (event.data.action === 'disableAllResources') {
                setAllResourcesDisabled(true);
            }
        };

        window.addEventListener("message", disableHandler);
        return () => {
            window.removeEventListener("message", disableHandler);
        };
    }, []);

    return (
        <DefaultLayout pageMeta={pageMeta} location={location} jobCode={jobCode}>
            <div data-template-name="template-c">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-c-intro">
                    <PageHeader title="HCP & Patient Resources" />
                    <PageSection bgColor="cloud" title="Information for You and Your Patients" centerTitle={true}>
                        <PageIntro centered={true}>
                            <p>Below you will find downloadable guides and information to use during treatment with KEYTRUDA.</p>
                        </PageIntro>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}
                {/* Begin Main Column */}
                <TemplateColumn id="template-c-main">
                    <PageSection title="For Health Care Professionals (HCPs)">
                        <ResourceLibrary items={resourceLibraryItemsForHcps} allResourcesDisabled={allResourcesDisabled}/>
                    </PageSection>

                    <PageSection title="For Your Patients" bgColor="cloud">
                        <ResourceLibrary items={resourceLibraryItemsForPatients} allResourcesDisabled={allResourcesDisabled}/>
                    </PageSection>

                    <PageSection bgColor='keytrudaGreen'>
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
                {/* End Main Column */}
            </div>
        </DefaultLayout>
    );
};

export default Page;

